<template>
  <div>

  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    return {}
  }
})
</script>

<style lang="less" scoped>

</style>
